<template>
    <LiefengContent>
        <template v-slot:title>广州市社区随约服务网上驿站运营数据周期汇报</template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                height="200"
                :loading="loading"
                :fixTable="true"
                :pagesizeOpts="[20, 30, 50, 100]"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: { LiefengTable, LiefengContent },
    data() {
        return {
            talbeColumns: [
                {
                    title: "报表名称",
                    align: "center",
                    minWidth: 180,
                    key: "reportName",
                },
                {
                    title: "最新生成时间",
                    align: "center",
                    minWidth: 180,
                    key: "gmtModified",
                    render: (h, params) => {
                        return h(
                            "span",
                            {},
                            params.row.gmtModified && params.row.gmtModified != '' ? this.$core.formatDate(new Date(params.row.gmtModified),"yyyy-MM-dd hh:mm:ss") : ''
                        )
                    },
                },
                {
                    title: "操作",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "Button",
                            {
                                props: {
                                    type: "primary",
                                    size: "small",
                                },
                                on: {
                                    click: () => {
                                         this.$core.openLayerFrame(
                                            {
                                                type: 2,
                                                title: params.row.reportName,
                                                content: `/page#/operationreportdetail?name=${params.row.reportName}&id=${params.row.id}&reportCode=${params.row.reportCode}`,
                                                area: ["100%", "100%"],
                                            }
                                        )
                                    },
                                },
                            },
                            "查看报表"
                        )
                    },
                },
            ],
            tableData: [],
            loading: false,
            page: 1,
            pageSize: 20,
            total: 0,
        }
    },
    created() {
        this.getList()
    },
    methods: {
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 接口部分
        getList() {
            this.loading = true
            this.$get("/statistic/api/symanage/pc/report/selectReportPage", {
                page: this.page,
                pageSize: this.pageSize,
            }).then(res => {
                if (res.code == 200 && res.dataList) {
                    this.loading = false
                    this.tableData = res.dataList.map(item => {
                        if (item.reportRule && item.reportRule != "") {
                            item.reportRule = JSON.parse(item.reportRule)
                        }
                        return {
                            ...item,
                            ...item.reportRule,
                        }
                    })
                }
                this.total = res.maxCount
            })
        },
    },
}
</script>

<style>
</style>